import React from "react";

import { Link } from "react-scroll";
import Button from "../button";
import Container from "../container";

import reconstruccionMamaria from "../assets/images/procedures/reconstruccionMamaria.webp";
import rinoplastia from "../assets/images/procedures/rinoplastia.webp";
import quemaduras from "../assets/images/procedures/quemaduras.webp";

import "./procedures.scoped.scss";

const Reconstructive = () => {
  const procedures = [
    {
      procedure: "Reconstrucción Mamaria",
      picture: reconstruccionMamaria,
      alt: "erro-loading-img",
      description:
        "Cuando hubo necesidad de quitar el pecho por cáncer, o por " +
        "algún otro motivo se puede hacer reconstrucción inmediata de dos formas: " +
        "Utilizando la piel y el músculo de la parte del abdomen " +
        "propio de la persona (colgajo de TRAM) o utilizando expansores " +
        "mamarios que se colocan debajo del músculo pectoral y se van inflando " +
        "progresivamente hasta alcanzar el tamaño deseado.",
    },
    {
      procedure: "Rinoplastía",
      picture: rinoplastia,
      alt: "erro-loading-img",
      description:
        "Se tratan fracturas nasales por accidentes que provoquen desviación " +
        "o hundimiento, que a su vez llevan a obstrucción nasal. Además, " +
        "se hace mejoría estética: donde puede realizarse afinamiento " +
        "y levantamiento de la punta nasal. También pude trabajarse la giva y el " +
        "tabique nasal cuando fuese necesario.",
    },
    {
      procedure: "Quemaduras",
      picture: quemaduras,
      alt: "erro-loading-img",
      description:
        "Tratamieno integral de las áreas de quemadura hasta que " +
        "estas cicatricen. " +
        "Cuando la quemadura es profunda y se pierde la piel hay que reponerla " +
        "con piel sana del paciente (injertos de piel).",
    },
  ];
  return procedures.map(({ procedure, picture, alt, description }) => (
    <div className={"procedure"} key={procedure}>
      <Container isWrapper className={"procedureContainer"}>
        <div className={"illustrationContainer"}>
          <div
            className={"illustration"}
            style={{ background: `url(${picture})` }}
            alt={alt}
          />
        </div>
        <div className={"description"}>
          <h4>{procedure}</h4>
          <p>{description}</p>
          <Link to="appointment" spy smooth offset={-70} duration={500}>
            <Button>Preguntar</Button>
          </Link>
        </div>
      </Container>
    </div>
  ));
};

export default Reconstructive;
