import React, { Component } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "../button";
import Container from "../container";
import logo from "../assets/images/logo.webp";

import "./navbar.scss";

export default class Navbar extends Component {
  state = { show: false };

  scrollToTop = () => {
    scroll.scrollToTop();
  };

  onHandleMenuAction = () => {
    this.setState((prevState) => ({
      show: !prevState.show,
    }));
  };

  onCloseMenu = () => {
    this.setState(() => ({
      show: false,
    }));
  };

  render() {
    const { show } = this.state;
    return (
      <nav className="nav" id="navbar">
        <Container isFlex>
          <div className="rightGroup">
            <div className="drMarcoLogo">
              <img src={logo} alt="Dr Marco Antonio" />
            </div>
          </div>

          <div className="leftGroup">
            <div className={`menu ${show ? "openedMenu" : ""}`}>
              <div className="options">
                <ul>
                  <li>
                    <Link
                      onClick={this.onCloseMenu}
                      to="home"
                      spy
                      smooth
                      offset={-70}
                      duration={1000}
                    >
                      Inicio
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={this.onCloseMenu}
                      to="about"
                      spy
                      smooth
                      offset={-70}
                      duration={500}
                    >
                      Acerca de mi
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={this.onCloseMenu}
                      to="procedures"
                      spy
                      smooth
                      offset={0}
                      duration={500}
                    >
                      Procedimientos
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={this.onCloseMenu}
                      to="appointment"
                      spy
                      smooth
                      offset={0}
                      duration={500}
                    >
                      Contacto
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={this.onCloseMenu}
                      to="location"
                      spy
                      smooth
                      offset={-70}
                      duration={500}
                    >
                      Ubicación
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="contact">
                <p>
                  Avenida las Americas 12-34 zona 13
                  <br />
                  Guatemala 01013, Guatemala
                  <br />
                  <a href="www.google.com">
                    <FontAwesomeIcon icon={["fas", "globe-americas"]} />
                    Ver mapa
                  </a>
                  <br />
                  <br />
                  <a href="www.gmail.com">cirplamarco@gmail.com</a>
                  <br />
                  <a href="www.google.com">+ (502) 5290 8776</a>
                </p>
              </div>
            </div>

            <div className="contactButton">
              <Link to="appointment" spy smooth offset={-70} duration={500}>
                <Button isOutlined>Agendar cita</Button>
              </Link>
            </div>

            <div className="menuButton">
              <Button
                onClick={this.onHandleMenuAction}
                theme="secondary"
                icon={["fas", "bars"]}
              />
            </div>
          </div>
        </Container>
      </nav>
    );
  }
}
