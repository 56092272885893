import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import MapComponent from "../map";
import Container from "../container";
import Button from "../button";

import "./location.scoped.scss";

const Location = () => (
  <div className={"location"}>
    <Container>
      <p className={"title"}>
        Visita nuestra clínica
        <br />
        <span>Encuéntranos en</span>
      </p>
    </Container>
    <div className={"mapContainer"}>
      <MapComponent />
      <div className={"box"}>
        <div className={"badge"}>
          <div>Visítanos en 6ta Avenida 9-18, z. 10, Sixtino II</div>
          <div>
            <FontAwesomeIcon icon={["fa", "calendar-day"]} />
            <span>
              De lunes a sábado
              <br />
              De 15:00 a 16:30 hrs.
            </span>
          </div>
          <div>
            <FontAwesomeIcon icon={["fa", "phone"]} />
            <span>
              +(502) 5206 2602
              <br />
            </span>
          </div>
          <div>
            <Button
              target="_blank"
              href="https://www.waze.com/ul?ll=14.60377779%2C-90.51293492&navigate=yes&zoom=17"
              isAnchor
              theme="secondary"
            >
              Indicaciones
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Location;
