import * as React from "react";

import "./section.scoped.scss";

const Section = ({ content, id, className }) => (
  <div
    className={`
        SectionContent
        ${className}
      `}
  >
    <div id={id}>{content}</div>
  </div>
);

export default Section;
