import React, { Component } from "react";
import { Link } from "react-scroll";
import emailRegex from "email-regex";

import Container from "../container";
import Button from "../button";

import firestore from "../firebase/firebase";
import "./footer.scoped.scss";

class Footer extends Component {
  state = {
    email: "",
    subscribed: false,
    badEmail: false,
  };

  onEmailChange = (prop, event) => {
    this.setState({ [prop]: event.target.value });
  };

  onSubscribe = () => {
    const { email } = this.state;
    if (emailRegex({ exact: true }).test(email)) {
      firestore
        .collection("newsletter")
        .add({ email })
        .then(() => {
          this.setState({
            subscribed: true,
            badEmail: false,
            email: "",
          });
        })
        .catch(() => {
          /* console.error('Error writing document'); */
        });
    } else {
      this.setState({ subscribed: false, badEmail: true });
    }
  };

  render() {
    const { email, subscribed, badEmail } = this.state;
    return (
      <div className={"footer"}>
        <Container>
          <div className={"gridContainer"}>
            <div className={"contact"}>
              <div className={"socials"}>
                <p className={"title"}>Conocer más</p>
              </div>
              <div className={"meta"}>
                <p>
                  <a href="www.gmail.com">drmarcosanchezcp@gmail.com</a>
                  <br />
                  <a href="www.google.com">+ (502) 5206 2602</a>
                  <br />
                  <br />
                  Visítanos en 6ta Avenida 9-18,
                  <br />
                  z. 10, Sixtino II, Guatemala, Guatemala
                  <br />
                </p>
              </div>
            </div>
            <div className={"links"}>
              <p className={"title"}>Navegación</p>
              <div className={"options"}>
                <ul>
                  <li>
                    <Link to="home" spy smooth offset={-70} duration={1000}>
                      Inicio
                    </Link>
                  </li>
                  <li>
                    <Link to="about" spy smooth offset={-70} duration={500}>
                      Acerca de mi
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="procedures"
                      spy
                      smooth
                      offset={-70}
                      duration={500}
                    >
                      Procedimientos
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="appointment"
                      spy
                      smooth
                      offset={-70}
                      duration={500}
                    >
                      Contacto
                    </Link>
                  </li>
                  <li>
                    <Link to="location" spy smooth offset={-70} duration={500}>
                      Ubicación
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className={"powered"}>
              <p className={"title"}>Suscribete al newsletter</p>
              <div className={"newsletter"}>
                <input
                  value={email}
                  className={"newsletter"}
                  placeholder="Correo"
                  onChange={(event) => {
                    this.onEmailChange("email", event);
                  }}
                />
                <Button theme="primary" onClick={this.onSubscribe}>
                  Enviar
                </Button>
              </div>
              <br />
              {subscribed ? "¡Suscrito!" : null}
              {badEmail ? "Email no valido" : null}
            </div>
          </div>
          <br />
          <hr />
          <br />
          <div className={"poweredby"}>
            <p>Powered by</p>
            <a
              href="http://www.darapti.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              DARAPTI
            </a>
            <p>info@darapti.com</p>
          </div>
        </Container>
      </div>
    );
  }
}

export default Footer;
