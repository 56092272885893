import * as React from "react";

import "./container.scoped.scss";

const Container = ({
  children,
  className,
  isFlex,
  isWrapper,
  isRelative,
  isFluid,
  clearPadding,
}) => (
  <div
    className={`
      container
      ${isFlex != null ? "isFlex" : ""}
      ${isRelative != null ? "isRelative" : ""}
      ${isFluid != null ? "isFluid" : ""}
      ${isWrapper != null ? "isWrapper" : ""}
      ${clearPadding != null ? "clearPadding" : ""}
      ${className || ""}
    `}
  >
    {children}
  </div>
);

export default Container;
