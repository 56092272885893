import React, { useState, useEffect } from "react";

import emailRegex from "email-regex";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Input from "./input";
import Button from "../button";
import firestore from "../firebase/firebase";

import { INITIAL_STATE } from "./state";

const Form = () => {
  const [state, setState] = useState(INITIAL_STATE);
  useEffect(() => emailjs.init(process.env.REACT_APP_EMAIL_JS_API_KEY), []);

  const submitHandler = (event) => {
    event.preventDefault();
    setState((prevState) => {
      return { ...prevState, goReCaptcha: !prevState.goReCaptcha };
    });
  };

  const sendAppointmentInfo = async (data) => {
    setState((prevState) => ({
      ...prevState,
      isSendingEmail: true,
      goReCaptcha: false,
    }));
    emailjs.send("service_7ebmz1a", "template_qkveNirJ", data).then(() => {
      setState({ ...INITIAL_STATE, hasSentEmail: true, isSendingEmail: false });
    });
  };

  const closeModal = () => {
    setState((prevState) => ({
      ...prevState,
      hasSentEmail: false,
      isSendingEmail: false,
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, openError: false }));
  };

  const inputChangedHandler = (event, inputIdentifier) => {
    const { appointmentForm } = state;
    const updatedOrderForm = {
      ...appointmentForm,
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier],
    };
    updatedFormElement.value = event.target.value;
    updatedOrderForm[inputIdentifier] = updatedFormElement;
    setState((prevState) => ({
      ...prevState,
      appointmentForm: updatedOrderForm,
    }));
  };

  const onReCaptchaChange = async () => {
    const { appointmentForm } = state;
    const formData = {};
    const keys = Object.keys(appointmentForm);
    for (let i = 0; i < keys.length; i += 1) {
      formData[keys[i]] = appointmentForm[keys[i]].value;
    }
    if (emailRegex({ exact: true }).test(formData.email)) {
      await firestore.collection("appointments").add(formData);
      await sendAppointmentInfo(formData);
    } else {
      setState((prevState) => ({
        ...prevState,
        isError: true,
        openError: true,
        errorMessage: "Email incorrecto",
        goReCaptcha: false,
      }));
    }
  };

  let onScreen;
  const {
    appointmentForm,
    hasSentEmail,
    isSendingEmail,
    isError,
    openError,
    errorMessage,
    goReCaptcha,
  } = state;
  const formElementsArray = [];

  const keys = Object.keys(appointmentForm);

  for (let i = 0; i < keys.length; i += 1) {
    formElementsArray.push({
      id: keys[i],
      config: appointmentForm[keys[i]],
    });
  }
  const inputs = formElementsArray.map((formElement) => (
    <Input
      key={formElement.id}
      elementType={formElement.config.elementType}
      elementConfig={formElement.config.elementConfig}
      value={formElement.config.value}
      changed={(event) => inputChangedHandler(event, formElement.id)}
    />
  ));

  if (goReCaptcha) {
    onScreen = <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY} onChange={onReCaptchaChange} />;
  } else if (isSendingEmail) {
    onScreen = <CircularProgress />;
  } else {
    onScreen = <Button type="submit">Enviar</Button>;
  }

  return (
    <div>
      {isError ? (
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={openError}
          autoHideDuration={6000}
          onClose={handleClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{errorMessage}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      ) : null}
      {hasSentEmail ? (
        <Dialog
          open={hasSentEmail}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Mensaje enviado con exito
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Tu mensaje se ha enviado con exito. Esta atento a tu celucar
              porque te llamaramos pronto para hablar contigo.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="primary" autoFocus>
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      <form onSubmit={submitHandler}>
        {inputs}
        {onScreen}
      </form>
    </div>
  );
};

export default Form;
