import React, { Component } from "react";

import Container from "../container";
import Aesthetic from "./aesthetic";
import Reconstructive from "./reconstructive";

import "./procedures.scoped.scss";

class Procedures extends Component {
  state = {
    viewing: false,
    showMore: false,
  };

  viewProcedureHandler = () => {
    this.setState({ viewing: true });
  };

  cancelViewProcedureHandler = () => {
    this.setState({ viewing: false });
  };

  showMoreHandler = () => {
    this.setState((prevState) => ({ showMore: !prevState.showMore }));
  };

  render() {
    return (
      <Container isFluid clearPadding className={"procedures"}>
        <div className={"grid"}>
          <p className={"title"}>
            Procedimientos
            <br />
            <span>Estéticos</span>
          </p>
          <Aesthetic />
          <p className={"title"}>
            Procedimientos
            <br />
            <span>Reconstructivos</span>
          </p>
          <Reconstructive />
        </div>
      </Container>
    );
  }
}

export default Procedures;
