import { library } from "@fortawesome/fontawesome-svg-core";

import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faArrowRight,
  faBars,
  faTimes,
  faGlobeAmericas,
  faCalendarDay,
  faClock,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  fab,
  faArrowRight,
  faBars,
  faTimes,
  faGlobeAmericas,
  faCalendarDay,
  faClock,
  faPhone,
);
