import React from "react";

import Container from "../container";
import photo from "../assets/images/doc.webp";

import "./about.scoped.scss";

const About = () => (
  <div className={"about"}>
    <Container>
      <div className={"aboutGrid"}>
        <div className={"photo"}>
          <img src={photo} alt="Dr Marco Antonio" />
        </div>
        <div className={"aboutMe"}>
          <h2>Doctor Marco Antonio</h2>
          <br />
          <div className={"text"}>
            Actualmente laborando como médico especialista B de Cirugía Plástica
            en el Hospital General de Accidentes, en Clínica Médica y en
            hospitales privados de Guatemala.
          </div>
          <div className={"text"}>Número de colegiado: 8482</div>
          <br />
          <div className={"university"}>
            <div className={"career"}>Médico y Cirujano</div>
            <div className={"name"}>Universidad Francisco Marroquín 1991</div>
          </div>
          <hr />
          <div className={"university"}>
            <div className={"career"}>Cirujano General</div>
            <div className={"name"}>
              Instituto Guatemalteco de Seguridad Social 1993-1996
            </div>
          </div>
          <hr />
          <div className={"university"}>
            <div className={"career"}>Cirujano Plástico</div>
            <div className={"name"}>Universidad de Guadalajara 1998-2001</div>
          </div>
          <div className={"inlinePhoto"}>
            <img src={photo} alt="Dr Marco Antonio" />
          </div>
        </div>
      </div>
    </Container>
  </div>
);
export default About;
