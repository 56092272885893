import React from "react";

import Form from "../form";
import Container from "../container";

import "./appointment.scoped.scss";

const Appointment = () => (
  <div className={"appointment"}>
    <Container>
      <h5>¿Tienes alguna pregunta o quieres agendar una cita?</h5>
      <span>
        <b>Escríbenos </b>y nosotros nos pondremos en contacto contigo lo antes
        posible.
      </span>
      <Form />
    </Container>
  </div>
);

export default Appointment;
