import React from "react";

import "./input.scoped.scss";

const Input = (props) => {
  const {
    elementType,
    label,
    elementConfig: { placeholder },
  } = props;

  let inputElement = null;
  switch (elementType) {
    case "input":
      inputElement = (
        <input
          className={"formControl"}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
          placeholder={null}
          required
        />
      );
      break;
    case "textarea":
      inputElement = (
        <textarea
          className={"formControl"}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
          placeholder={null}
          rows="3"
          required
        />
      );
      break;
    default:
      inputElement = (
        <input
          className={"formControl"}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
          type="text"
          required
        />
      );
  }
  return (
    <div className={"wrapper"}>
      {inputElement}
      <label htmlFor="input" id={label} className={"formControlLabel"}>
        {placeholder}
      </label>
    </div>
  );
};

export default Input;
