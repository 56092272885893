const INITIAL_STATE = {
    hasSentEmail: false,
    isSendingEmail: false,
    isError: false,
    openError: false,
    errorMessage: "",
    goReCaptcha: false,
    appointmentForm: {
        name: {
            elementType: "input",
            elementConfig: {
                type: "text",
                placeholder: "Nombres",
            },
            value: "",
        },
        phone: {
            elementType: "input",
            elementConfig: {
                type: "text",
                placeholder: "Teléfono",
            },
            value: "",
        },
        email: {
            elementType: "input",
            elementConfig: {
                type: "text",
                placeholder: "Correo",
            },
            value: "",
        },
        message: {
            elementType: "textarea",
            elementConfig: {
                type: "text",
                placeholder: "Mensaje",
            },
            value: "",
        },
    },
}

export {
    INITIAL_STATE
}
