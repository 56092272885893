import React from "react";
import GoogleMapReact from "google-map-react";

const customMapStyle = [
  {
    featureType: "landscape.man_made",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.station.bus",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.station.rail",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

const MapComponent = () => {
  const defaultProps = {
    center: {
      lat: 14.60396,
      lng: -90.510542,
    },
    zoom: 11,
  };

  return (
      <GoogleMapReact
          style={{ styles: customMapStyle }}
          bootstrapURLKeys={{ key: "AIzaSyD-DQw5qeUuH_rHtNKgdJ7BkUaXdVh_Zp4" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
      />
  );
};

export default MapComponent;
