import React from "react";

import { Link } from "react-scroll";
import Button from "../button";
import Container from "../container";

import lipoesculturaAbdominal from "../assets/images/procedures/lipoesculturaAbdominal.webp";
import aumentoDeBusto from "../assets/images/procedures/aumentoDeBusto.webp";
import rejuvenecimientoFacial from "../assets/images/procedures/rejuvenecimientoFacial.webp";

import "./procedures.scoped.scss";

const Aesthetic = () => {
  const procedures = [
    {
      procedure: "Abdominoplastía",
      picture: lipoesculturaAbdominal,
      alt: "erro-loading-img",
      description:
        "Lo que busca es aplanar el abdomen mediante la resección de piel " +
        "del abdomen bajo y medio, ajustando los músculos del abdomen. " +
        "Esta cirugía se puede combinar con liposucción de cintura y espalda baja " +
        "para trabajar toda la circunferencia del abdomen y con esto " +
        "obtener un mejor resultado.",
    },
    {
      procedure: "Del Busto",
      picture: aumentoDeBusto,
      alt: "erro-loading-img",
      description:
        "Puede aumentarse, reducirse y reafirmarse. " +
        "El aumento se logra colocando implantes de gel con silicón, los cuales " +
        "pueden colocarse haciendo un bolsillo por delante o por detras del músculo " +
        "pectoral. " +
        "Para reducir se hacen cortes en el mismo con lo que se levanta también el pezón " +
        "y se reduce el tamaño cuando es necesario. " +
        "La reafirmación se hace cuando el pezón esta caído, si se desea, además de levantar " +
        "el pezón se puden hacer pequeños implantes para aumentar el tamaño.",
    },
    {
      procedure: "Rejuvenecimiento Facial",
      picture: rejuvenecimientoFacial,
      alt: "erro-loading-img",
      description:
        "Cirugía de parpados para rejuvencer ojos donde se trabajan " +
        "los dos parpados superiores y los dos inferiores junto con las cejas. " +
        "También, de mejillas y cuello, que con el tiempo la piel esta parte " +
        "tiende a caer, con este cirugía se reajusta la piel en estas áreas. " +
        "Como alternativa para la cara se puede aplicar: Toxina Botolímica " +
        "en la frente y entre las cejas que busca disminuir las lineas de expresión " +
        "también se puede inyectar grasa para rellenar líneas que van de la naríz a la boca, " +
        "de la boca hacia abajo y en las mejillas para disminuir las líneas de expresión.",
    },
  ];
  return procedures.map(({ procedure, picture, alt, description }) => (
    <div className={"procedure"} key={procedure}>
      <Container isWrapper className={"procedureContainer"}>
        <div className={"illustrationContainer"}>
          <div
            className={"illustration"}
            style={{ background: `url(${picture})` }}
            alt={alt}
          />
        </div>
        <div className={"description"}>
          <h4>{procedure}</h4>
          <p>{description}</p>
          <Link to="appointment" spy smooth offset={-70} duration={500}>
            <Button>Preguntar</Button>
          </Link>
        </div>
      </Container>
    </div>
  ));
};

export default Aesthetic;
