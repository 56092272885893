import React from "react";
import { Link } from "react-scroll";

import Button from "../button";
import Container from "../container";
import logo from "../assets/images/logo.webp";
import centerImage from "../assets/images/whole_body.webp";

import "./home.scoped.scss";

const EVENTUALLY_REMOVE_THIS_PLEASE = {
  paddingTop: "10rem",
  paddingBottom: "5rem",
};

const Home = () => (
  <div className="start">
    <div className="drMarcoLogo">
      <img src={logo} alt="Dr Marco Antonio" />
    </div>
    <Container isRelative>
      <div className="presentation" style={EVENTUALLY_REMOVE_THIS_PLEASE}>
        <h1>Marco Antonio Sánchez</h1>
        <h2>Cirujano Plástico</h2>
        <p>
          Doctor especializado en cirugía plástica estética y reconstructiva en
          Guatemala.
        </p>
        <Link to="about" spy smooth offset={-150} duration={500}>
          <Button icon={["fas", "arrow-right"]}>Acerca de mi</Button>
        </Link>
        <div className="socials"></div>
      </div>
      <div className="illustration">
        <img className="mainImage" src={centerImage} alt="Cirujía Plástica" />
        <div className="circle">
          <span>Párpados y mejillas</span>
          <span>Aumento de busto</span>
          <span>Lipoescultura abdominal</span>
        </div>
      </div>
    </Container>
  </div>
);

export default Home;
