import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./button.scss";

const UseAnchor = ({ children, ...props }) => <a {...props}>{children}</a>;

const UseButton = ({ children, ...props }) => (
  <button type="button" {...props}>
    {children}
  </button>
);

const Button = ({
  className = "",
  children,
  isAnchor = false,
  isOutlined = false,
  isCentered = false,
  icon,
  theme = "primary",
  ...props
}) => {
  const Component = isAnchor ? UseAnchor : UseButton;

  return (
    <Component
      {...props}
      className={`
              button
              ${theme}
              ${icon != null ? "iconButton" : ""}
              ${isOutlined ? "outlined" : ""}
              ${isCentered ? "center" : ""}
              ${icon != null && children === undefined ? "onlyIcon" : ""}
              ${className}
          `}
    >
      {children}

      {icon != null && (
        <span>
          <FontAwesomeIcon icon={icon} />
        </span>
      )}
    </Component>
  );
};

export default Button;
