import Navbar from "../navbar";
import LazyLoad from "react-lazyload";

import Appointment from "../appointment";
import Section from "../section";
import Home from "../home";
import About from "../about";
import Procedures from "../procedures";
import Location from "../location";
import Footer from "../footer";

import "./index.scoped.scss";

function App() {
  return (
    <div>
      <Navbar />
      <LazyLoad height={200}>
        <Section content={<Home />} id="home" />
      </LazyLoad>
      <LazyLoad height={200}>
        <Section className="heightAuto" content={<About />} id="about" />
      </LazyLoad>
      <LazyLoad height={200}>
        <Section content={<Procedures />} id="procedures" />
      </LazyLoad>
      <LazyLoad height={200}>
        <Section
          className="heightAuto"
          content={<Appointment />}
          id="appointment"
        />
      </LazyLoad>
      <LazyLoad height={200}>
        <Section className="heightAuto" content={<Location />} id="location" />
      </LazyLoad>
      <Footer />
    </div>
  );
}

export default App;
